@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";
@import (reference) "src/main/webapp/less/71/mixins/_hero.less";
@import (reference) "src/main/webapp/less/71/mixins/_testPrepClaims.less";
@import (reference) "_home";
@import "../../global/components/_test-prep-claims.less";
@import "../../global/components/_flashcard.less";
@import "../../global/components/_dial-gauge.less";
//@import "../../global/components/_practice-questions-module.less";
main {
	background: transparent;
}
.topnav {
	background: transparent;
	@media (min-width: @screen-laptop) {
		padding-top: 15px;
		padding-bottom: 15px;
		height: auto;
	} 
	.topnav__sections {
		background: transparent;
		
		@media (min-width: @screen-laptop) {
			max-width: (@max-width-hero - 250px);
		}
	}
	
	
	#sign-up-button {
		.pill-button();
		height: auto;
		margin: 0;
	}
}

header.hero {
	margin-top: -88px;
	@media (min-width: @screen-tablet) {
		background: url("/resources/img/71/ppc-hero-tablet.png") right bottom no-repeat;
	}
	
	@media (min-width: @screen-laptop) {
		background: url("/resources/img/71/ppc-hero.png") 50% top/cover no-repeat;
	}
	
	.mobile-hero-image {
		background: url("/resources/img/71/ppc-hero-mobile.png") bottom/cover no-repeat;
		min-height: 450px;
		@media (min-width: @screen-tablet) {
			display: none;
		}
	}
	
	h1 {
		text-align: center;
	}
	
	.pill-button {
		min-width: 150px;
	}
	
	section.content {
		padding: 30px 40px 0;
		
		> p {
			font-size: @font-size-large;
		}
		
		@media (min-width: 900px) {
			> h1, > p {
				max-width: 60% !important;
			}
		}
		
		@media (min-width: @screen-tablet) {
			> h1, > p {
				max-width: 360px;
				text-align: left;
			}
			
			text-align: left;
			padding: 70px 50px;
		}
		
		@media (max-width: @screen-tablet) {
			text-align: center;
		}
	}
}

main {
	padding-top: 0;
	
	.section-title {
		.font-h2();
		color: @color-gray-5;
		text-align: center;
		margin-bottom: @spacing-standard;
	}
	
	h2 {
		text-align: center;
		margin-bottom: 30px;
	}
	
	a.btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		
		&:hover {
			text-decoration: none;
		}
	}
	
	#practice-tests {
		text-align: center;
		padding-top: 100px;
		padding-left: 0;
		padding-right: 0;
		
		@media (min-width: @screen-mobile) {
			padding-bottom: 90px;
		}
		
		h2 {
			padding-left: 30px;
			padding-right: 30px;
			//font-size: @font-size-largerer;
		}
		
	/*	p {
			font-size: @font-size-large;
			margin-bottom: @spacing-large;
			padding-left: 30px;
			padding-right: 30px;
		}
		*/
		fieldset {border: none}
		
		.swiper-slide {
			@media (max-width: @screen-xs-max) {
				padding: 0 30px;
			}
		}
		
		.question-carousel-pagination {
			padding-top: 50px;
		}
	}
	.practice-tests {
		img {
			width: 100%;
			max-width: 600px;
		}
	}
}

.hidden-md {
	@media (min-width: @screen-laptop) {
		display: none;
	}
}

.hidden-sm {
	@media (min-width: @screen-tablet) {
		display: none;
	}
}

.gray-1-bleed {
	background-color: @color-gray-1;
}


.swiper-wrapper {
	align-items: center;
}

button, input[type=button], a.btn {
	&.next {
		padding-right: 0;
		width: 220px;
		font-weight: @font-weight;
		letter-spacing: 0.03em;
		text-transform: none;
		
		&:after {
			display: none;
		}
	}
}

.swiper-pagination-bullet {
	background-color: @color-gray-1;
	border: 1px solid @color-blue-3;
	opacity: 1;
	width: 12px;
	height: 12px
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: @color-blue-3;
	border: none;
}
section.content.what-we-offer {
	padding-bottom: 0;
	padding-top: 80px;
}
.what-we-offer {
	background: @color-brand-light-bg;
	@media(min-width: @screen-laptop){
		background: url("/resources/img/71/what-we-offer.png") right no-repeat @color-brand-light-bg;
	}
	.content{
		padding: 0;
	}
	
	.what-we-offer__text-title {
		display: flex;
		align-items: center;
		padding-bottom: 30px;
		
		@media (max-width: @screen-mobile-max) {
			padding-bottom: 10px;
		}
		
		p {
			padding-left: 15px;
			text-align: left;
		}
		
		em {
			font-style: normal;
			font-weight: @font-weight-bold;
		}
	}
	.what-we-offer__we-provide {
		margin-bottom: 20px;
	}
	.image-and-detail {
		margin-bottom: 0;
	}
	.image-and-detail__content {
		padding: 80px 15px 30px;
		margin-bottom: 0;
		background: @color-brand-light-bg;
		@media (min-width: @screen-laptop) {
			padding-bottom: 120px;
		}
	}
	.image-and-detail__image-container {
		@media (min-width: @screen-tablet) {
			max-width: none;
			height: auto;
		}
	}
	
	.image-and-detail__image--offer {
		width: 120%;
		max-width: 120%;
		margin-left: -30px;
		margin-right: -30px;
		@media (min-width: @screen-tablet) {
			width: 100%;
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
			height: auto;
			position: relative;
			
		}
		@media(min-width: @screen-laptop){
			display: none;
		}
	}
}
#personalized-learning {
	.image-and-detail__title {
		.font-h3();
	}
}
.links {
	@media(min-width: @screen-tablet) {
		width: 100%;
	}
}
.links .footer-section{
	@media(min-width: @screen-tablet){
		justify-content: space-between;
		max-width: none;
		width: 100%;
	}
}
.links .footer-section ul.list-items.ppc {
	@media(min-width: @screen-mobile){
		justify-content: space-between;
	}
}

.watch-and-learn, .watch-and-learn ul {
	text-align: left;
}
.watch-and-learn h2 {
	text-align: center;
	@media(min-width: @screen-tablet){
		text-align: left;
	}
}