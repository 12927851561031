@import (reference) "../_variables";
@import (reference) "../global";

header.hero {
	.hero__title-container {
		p {
			max-width: 550px;
		}
	}
}

section > .content {
	padding-top: 80px;
	padding-bottom: 80px;
	
	@media(min-width: @screen-laptop){
		padding-top: 120px;
		padding-bottom: 120px;
	}
	
	&.no-top-padding {
		padding-top: 0;
	}
	
	h3 {
		.font-h2();
	}
	
	h1, h3 {
		margin-bottom: 20px;
		
		@media(min-width: @screen-laptop){
			margin-bottom: 30px;
		}
	}
	
	:not(.all-suites-box) ul {
		.bullet();
		margin-top: 20px;
		
		li {
			margin-bottom: 20px;
			
			@media(min-width: @screen-laptop){
				margin-bottom: 15px;
			}
		}
	}
}

section.expert-contributors-section {
	
	h2 {
		text-align: center;
	}
	
	h3 {
		.font-body();
		font-weight: @font-weight-bolder;
		text-align: center;
		
		@media(min-width: @screen-laptop) {
			text-align: left;
		}
	}
	
	.expert {
		
		display: flex;
		flex-direction: column;
		margin-top: 60px;
		
		@media(min-width: @screen-laptop) {
			flex-direction: row;
		}
		
		&:first-of-type {
			margin-top: 40px;
			
			@media(min-width: @screen-laptop) {
				margin-top: 60px;
			}
		}
		
		table {
			order: 1;
			max-width: 164px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
			overflow-x: auto;
			
			@media(min-width: @screen-laptop) {
				margin-left: 0;
				margin-right: 70px;
				margin-bottom: 0;
			}
			
			tr {
				border-bottom: none;
				
				td {
					padding: 0;
					vertical-align: baseline;
					
					img {
						object-fit: cover;
						width: 164px;
						height: 164px;
						
						@media(min-width: @screen-laptop) {
							margin-top: 38px;
						}
					}
				}
			}
		}
		
		.expert-name-bio {
			order: 2;
		}
		
		caption {
			display: none;
		}
	}
}

section.content.cta-video-lessons-small {
	background-color: @color-brand-gray-bg;
	padding-top: 80px;
	padding-bottom: 50px;
	
	@media(min-width: @screen-laptop) {
		padding-top: 120px;
		padding-bottom: 90px;
	}
}