@import (reference) "../_variables";

.faqs {
	margin-top: 0;
}

.faqs__list {
	margin-top: 40px;
	
	@media (min-width: @screen-tablet) {
		margin-top: 70px;
	}
}

.faqs__faq__header {
	margin-bottom: 20px;
}