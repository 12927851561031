@import (reference) "../_variables";

.all-suites-box {
	background: @color-white;
	box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
	border-radius: 15px;
	padding: 50px 12px;
	
	@media (min-width: @screen-tablet) {
		padding: 80px 60px;
	}
	
	ul.link-rows li a {
		
		.link-action {
			white-space: nowrap;
		}
		
		&:after {
			.material-skinny-arrow();
		}
	}
	
	@media(max-width: @screen-mobile-max) {
		ul.link-rows li {
			padding: 50px 0;
			
			&:first-child {
				padding-top: 30px;
			}
			
			a {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				
				.link-header {
					font-size: @font-size;
				}
				
				.link-name {
					margin-top: 24px;
					margin-bottom: 40px;
					padding-left: 15px;
					padding-right: 15px;
					max-width: unset;
				}
				
				.link-action {
					font-weight: @font-weight-bold;
					
					&:after {
						.material-skinny-arrow();
					}
				}
			}
		}
	}
}