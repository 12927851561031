@import (reference) "../../mixins/_styleResets.less";
@import (reference) "src/main/webapp/less/mixins/_icons.less";

.flip(@howFar) {
	transform: rotate3d(1, 0, 0, @howFar);
}

.flashcards {
	position: relative;
	display: flex;
	flex-direction: column;
	
	@media (min-width: @screen-tablet) {
		flex-direction: row;
		gap: 150px;
	}
}

.flashcards__container {
	position: relative;
	perspective: 600px;
	width: 100%;
	@media(max-width:@screen-mobile-max){
		order: 2;
		margin-top: 30px;
	}
}

.flashcard {
	margin-bottom: 30px;
	width: 100%;
	height: 220px;
	transform-style: preserve-3d;
	transition: 600ms ease-out;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: 0;
		height: 260px;
	}
	
	&.flipped {
		.flip(.5turn);
	}
}

.flashcard__side {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	background-color: @color-white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	//color: @color-gray-darker;
	font-size: 14px;
	font-weight: @font-weight-semibold;
	line-height: 28px;
	border-radius: 6px;
	overflow: hidden;
	will-change: transform;
	
	@media (min-width: @screen-tablet) {
		font-size: 16px;
	}
	
	@media (min-width: @screen-laptop) {
		font-size: 18px;
	}
	
	&.flashcard__side--front {
		.flip(0);
	}
	
	&.flashcard__side--back {
		.flip(.5turn);
	}
}

.flashcards__content {
	display: flex;
	flex-direction: column;
	text-align: center;
	
	@media (min-width: @screen-tablet) {
		justify-content: center;
		text-align: left;
	}
}

/*.flashcards__title {
	margin: 0 0 10px;
	font-size: 28px;
	font-weight: @font-weight-normal;
	line-height: 38px;
	
	@media (min-width: @screen-tablet) {
		margin: 0 0 15px;
		font-size: 36px;
		line-height: 50px;
	}
}*/

.flashcards__description {
	font-size: 18px;
	line-height: 28px;
}

.flashcards__floating-background {
	display: none;
	
	@media (min-width: @screen-tablet) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		z-index: -1;
		top: 0;
		right: -150px;
		padding: 35px 0 25px;
		height: 100%;
		width: 260px;
	}
}

.flashcards__floating-card {
	background-color: @color-white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 9px;
	overflow: hidden;
}

.flashcards__floating-card--big {
	width: 200px;
	height: 130px;
	transform: matrix(0.97, -0.26, 0.26, 0.97, 0, 0);
}

.flashcards__floating-card--small {
	align-self: center;
	margin-left: 70px;
	width: 70px;
	height: 45px;
	transform: matrix(0.98, 0.21, -0.21, 0.98, 0, 0);
}
