@import (reference) "../_variables.less";

.shopping-course__cart {
	border-top: 1px solid @color-gray-3;
	border-bottom: 1px solid @color-gray-3;
	padding: 30px 0;
	margin: 30px auto;
	
	> * {
		@media(min-width: @screen-mobile) {
			max-width: 290px;
		}
		margin: 0 auto;
	}
	
	.shopping-course__cart__pricing {
		display: flex;
		align-items: baseline;
		white-space: nowrap;
		padding-bottom: 15px;
		
		.shopping-course__cart__price {
			flex: 0 0 auto;
			color: @color-brand;
			font-family: @font-family-header;
			font-size: 26px;
			font-weight: @font-weight-bold;
			justify-self: left;
		}
		
		.shopping-course__cart__original-price {
			flex: 0 0 auto;
			margin-left: 15px;
			font-size: 16px;
			text-decoration: line-through;
			justify-self: center;
		}
		
		.shopping-course__cart__discount {
			flex: 0 0 auto;
			margin-left: 15px;
			background-color: @color-button;
			color: @color-white;
			font-size: 16px;
			padding: 0 15px;
			border-radius: 4px;
			text-align: center;
			vertical-align: middle;
			justify-self: right;
			align-self: center;
		}
		
		.margin-auto {
			margin: 0 auto;
		}
	}
	
	.shopping-course__cart__best-value {
		font-size: 14px;
		padding-bottom: 10px;
		white-space: nowrap;
	}
	
	.hr-with-text {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr auto 1fr;
		grid-column-gap: 15px;
		align-items: start;
		margin: 25px auto 5px;
		font-family: @font-family-header;
		font-size: 14px;
		font-weight: @font-weight-bold;
		
		&::before, &::after {
			content: "";
			height: ~"calc(50% - 0.5px)";
			border-bottom: 1px solid @color-gray-3;
		}
	}
	
	.shopping-course__cart__monthly-price {
		text-align: center;
		font-weight: @font-weight-semibold;
		font-size: 16px;
		margin: 15px auto 10px;
		align-items: center;
		justify-content: space-between;
		
		.btn-block {
			font-size: 18px;
		}
	}
	
	ul.shopping-course__cart__disclaimers {
		list-style-type: none;
		padding: 0;
		margin: 0 auto;
		
		> li {
			padding: 0;
			font-size: 13px;
			&::before {
				content: "*";
			}
		}
	}
	
	.shopping-course__cart__button {
		@media(min-width: @screen-mobile) {
			width: 290px;
		}
		margin-top: 10px;
		
		.btn {
			display: inline-block;
			font-size: 20px;
			font-weight: @font-weight;
			margin: 0;
			padding: 10px 0;
			height: auto;
			width: 100%;
		}
	}
	
	@media (min-width: @screen-tablet) {
		border: 1px solid @color-gray-3;
		border-radius: 6px;
		padding: 30px 20px;
		margin: 0;
	}
}