@import (reference) "../_variables.less";

@max-content-width: 1200px;

main {
	@media(max-width: @screen-mobile-max) {
		padding-top: 0;
	}
}

.max-width-mobile {
	@media (max-width: @screen-mobile-max) {
		display: block;
		width: 100%;
		padding: 0 15px;
	}
}

.full-width-mobile {
	@media (max-width: @screen-mobile-max) {
		display: block;
		width: 100%;
	}
}

.max-width-desktop {
	@media (min-width: @screen-tablet) {
		display: block;
		width: 100%;
		max-width: @max-content-width + 30px;
		padding: 0 15px;
		margin: 0 auto;
	}
}

.full-width-desktop {
	@media (min-width: @screen-tablet) {
		display: block;
		width: 100%;
	}
}

.shopping-course-v2 {
	display: block;
	
	@supports (display: grid) {
		@media (min-width: @screen-tablet) {
			display: grid;
			
			grid-template-areas:
				"title sidebar"
				"product-box sidebar"
				"pitch-abstract sidebar"
				"hr sidebar"
				"pitch-summary sidebar"
				"pitch-benefits sidebar"
				"testimonials sidebar"
		;
			grid-template-columns: 1fr min-content;
			grid-column-gap: 45px;
			grid-row-gap: 45px;
			
			.shopping-course__title {
				grid-area: title;
			}
			
			.pb-wrapper {
				grid-area: product-box;
			}
			
			.hr {
				grid-area: hr;
			}
			
			.sidebar {
				grid-area: sidebar;
				
				.sticky {
					position: sticky;
					top: 15px;
				}
			}
			
			.shopping-course__pitch {
				&.summary {
					grid-area: pitch-summary;
				}
				
				&.abstract {
					grid-area: pitch-abstract;
				}
				
				&.benefits {
					grid-area: pitch-benefits;
				}
			}
			
			.shopping-course__testimonials {
				grid-area: testimonials;
			}
		}
		
		@media (min-width: @screen-laptop) {
			grid-template-areas:
				"title title sidebar"
				"product-box pitch-abstract sidebar"
				"hr hr sidebar"
				"pitch-summary pitch-summary sidebar"
				"pitch-benefits pitch-benefits sidebar"
				"testimonials testimonials sidebar"
		;
			grid-template-columns: max-content 1fr min-content;
		}
	}
}

.shopping-course-v2 .shopping-course__product-box {
	max-width: 330px;
	margin: 0 auto;
	@media(max-width: @screen-mobile-max) {
		padding-top: 30px;
	}
	
	img {
		width: 100%;
		box-shadow: 6px 6px 3px 1px @color-gray-3;
	}
}

.hr {
	border-bottom: 1px solid @color-gray-e;
	width: 100%;
}

.shopping-course-v2 { // for specificity; helps override ppcTestPrep.less
	
	.ppc-course__testimonials__title {
		display: none;
	}
	
	.content-hub__testimonials__source {
		display: flex;
		align-items: center;
	}
}