@import (reference) "../_variables.less";

.shopping-course__title {
	font-size: 36px;
	font-family: @font-family-header;
	font-weight: @font-weight-bold;
	color: @color-brand;
	text-align: center;
	line-height: 1.3em;
	
	@media (max-width: @screen-xs-max) {
		font-size: 24px;
		margin: 30px 0;
	}
	
	@media(min-width: @screen-tablet) {
		text-align: left;
	}
}