@import (reference) "../_variables.less";

.etcetera-sidebar {
	display: none;
	
	border: 1px solid @color-gray-3;
	border-radius: 6px;
	
	padding: 20px;
	text-align: center;
	margin: 30px auto;
	
	.icons {
		padding-bottom: 15px;
	}
	
	.message {
		font-family: @font-family;
		font-style: italic;
		font-size: 20px;
	}
	
	@media (min-width: @screen-tablet) {
		display: block;
	}
}
