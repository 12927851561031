@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

.hero() {
	@left-content-width: 60%;
	@figure-width: 45%;
	
	@left-content-max-width: 570px;
	@figure-max-width: 488px;
	
	z-index: 0;
	position: relative;
	background-image: none;
	text-align: center;
	overflow: hidden;
	
	@media (min-width: @screen-tablet) {
		text-align: left;
	}
	
	section.content {
		color: @color-header;
		margin-top: 20px;
		
		@media (min-width: @screen-tablet) {
			> h1, > p {
				max-width: @left-content-max-width;
				width: @left-content-width;
			}
		}
	}
	
	figure.hero-image {
		width: @figure-width;
		max-width: @figure-max-width;
		
		z-index: -1;
		
		position: absolute;
		top: 45px;
		margin: 0;
		
		right: ~"calc(max(-60px, (100vw - @{max-width-hero}) / 2))";
		
		@media (max-width: @screen-mobile-max) {
			display: none;
		}
	}
}