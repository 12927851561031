@import (reference) "../_variables.less";
@import (reference) "_shopMain.less";

.exclusive-banner {
	&, & * {
		transition: all 200ms ease-out;
	}

	grid-area: banner;
	background-color: @color-brand-darker;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: @max-content-width;
	margin: 0 auto 30px;
	@media (min-width: @screen-tablet) {
		margin: 0 auto 55px;
	}
	
	padding: 0 15px;
	@media(max-width: @screen-xs-max) {
		padding: 15px;
	}
	
	.get {
		flex: 0 0 auto;
		
		font-size: 16px;
		font-weight: @font-weight-semibold;
		font-family: @font-family-header;
		color: @color-white;
	}
	
	.starburst {
		flex: 0 0 auto;
		
		&, & > * {
			position: relative;
		}
		width: 95px;
		height: 85px;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		line-height: 1;
		
		svg {
			position: absolute;
			top: 5px;
			left: 10px;
			width: 75px;
			height: 75px;
			
			polygon {
				fill: @color-button;
			}
		}
	}
	
	.discount {
		font-size: 26px;
		color: white;
		font-weight: @font-weight-bold;
		text-transform: uppercase;
		font-family: "Open Sans Black", @font-family;
		
		padding-left: 5px; // nudging it a little off-center for visual perfection
		
		&::after { // percent
			content: "%";
			font-size: 18px;
			color: white;
			font-weight: @font-weight-semibold;
			text-transform: uppercase;
			font-family: @font-family;
		}
	}
	
	.off {
		font-size: 16px;
		color: white;
		font-weight: @font-weight-semibold;
		text-transform: uppercase;
	}
	
	.message {
		flex: 0 1 auto;
		font-size: 14px;
		color: white;
		font-family: @font-family-header;
		font-weight: @font-weight-bold;
	}
	
	@media (min-width: @screen-tablet) {
		.get {
			font-size: 20px;
		}
		
		.starburst {
			width: 160px;
			height: 90px;
			
			svg {
				top: -15px;
				left: 20px;
				width: 120px;
				height: 120px;
			}
		}
		
		.discount {
			font-size: 44px;
			font-weight: @font-weight-bold;
			padding-left: 10px; // nudging it a little off-center for visual perfection
			
			&::after { // percent
				font-size: 30px;
				font-weight: @font-weight;
			}
		}
		
		.off {
			font-size: 18px;
		}
		
		.message {
			font-size: 20px;
		}
	}
}