@import (reference) "../_variables.less";

.shopping-course__testimonials {
	padding-top: 20px;
	@media(max-width: @screen-mobile-max) {
		padding-top: 30px;
	}
	
	.shopping-course__testimonials__intro {
		font-family: @font-family-header;
		font-size: 22px;
		color: @color-brand;
		font-weight: @font-weight-bold;
	}
	
	.shopping-course__testimonial-list {
		background-color: @color-gray-1;
		padding: 60px 50px 0;
		margin-top: 30px;
	}
	
	.testimonial {
		padding-bottom: 60px;
	}
	
	.testimonial__quote {
		font-family: @font-family-header;
		font-style: italic;
		font-size: 24px;
		
		&:before {
			content: "\201C";
			margin-right: -5px;
		}
		
		&:after {
			content: "\201D";
			margin-left: -5px;
		}
	}
	
	.testimonial__author {
		font-size: 16px;
		text-transform: uppercase;
	}
}
