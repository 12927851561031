@import (reference) "../_variables";

.table-of-contents {
	background: @color-white;
	box-shadow: 0 8px 34px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	padding: 58px 28px;
	margin-top: 5px;
	margin-bottom: 80px;
	
	@media (min-width: @screen-tablet) {
		padding: 70px 120px;
		margin-top: 60px;
		margin-bottom: 120px;
	}
}

.table-of-contents__header {
	.font-h2();
	margin-bottom: 30px;
	
	@media (min-width: @screen-tablet) {
		margin-bottom: 48px;
	}
}

.table-of-contents__list {
	
	list-style: none;
	padding-inline-start: 0;
	
	@media (min-width: @screen-tablet) {
		column-count: 2;
	}
	
	li {
		margin-bottom: 30px;
		
		&:before {
			.material-outline(@link-arrow-icon);
			font-size: @link-arrow-font-size;
			position: relative;
			top: 8px;
		}
	}
	
	a {
		text-decoration: underline;
		padding-left: 14px;
	}
}

.table-of-contents__cta-container {
	margin-top: 50px;
	@media(min-width: @screen-tablet){
		margin-top: 60px;
	}
	
	.pill-button {
		margin-top: 0;
		margin-bottom: 0;
	}
}