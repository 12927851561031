@import (reference) "../_variables";
@import (reference) "../../mixins/_icons";
@import (reference) "../../mixins/_styleResets";

main {
	background: transparent;
	h2 {
		text-align: center;
	}

	padding-top: 0;

	section.testimonials.content {
		padding-top: 0;
	}
	
	section.content,
	section > .content {
		padding-top: 80px;
		
		@media (min-width: @screen-tablet) {
			padding-top: 120px;
		}
	}
	
	section.content:nth-of-type(even) {
		background-color: @color-white;
		position: relative;
		z-index: 1;
	}

	figure + h2 {
		margin-top: @spacing-paragraph;
	}

	overflow-y: visible;
}

section.content > * {
	position: relative;
	z-index: 1;
}

header.hero {
	
	.hero-background-alt-color {
		background: linear-gradient(345.87deg, #ED7918 -1.03%, #F89D1F 98.8%);
		height: 100%;
		width: 22%;
		position: absolute;
		right: 0;
		top: 0;
		display: none;
		
		@media (min-width: @screen-tablet) {
			display: block;
		}
		
		@media (min-width: @screen-xl) {
			width: 25%;
		}
	}
	
	h1, p {
		text-align: center;
		color: @color-header;
		
		@media (min-width: @screen-tablet) {
			text-align: left;
		}
	}
	
	.subheader {
		margin-top: 20px;
		
		@media (min-width: @screen-tablet) {
			margin-top: 30px;
		}
	}

	background-color: @color-brand-light-bg;

	@left-content-width: 66%;
	@figure-width: 74%;

	@left-content-max-width: 657px;
	@figure-max-width: 796px;

	border-bottom: none;
	background-image: none;

	position: relative;

	@media (min-width: @screen-tablet) {
		min-height: ~"calc(min(672px,61vw))";
		padding-bottom:150px;
	}

	section.content,
	section > .content {
		padding-top: 60px;
		
		@media (min-width: @screen-tablet) {
			> h1, > p {
				max-width: @left-content-max-width;
				width: @left-content-width;
				
				text-align: left;
			}
		}
	}

	.hero-image {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 30px;
		margin-left: 110px;
		
		img {
			width: 331px;
			margin-left: 110px;
		}
		
		.hero-feature-boxes {
			position: relative;
			top: 0;
			left: 0;
		}
		
		.hero-feature-box {
			
			background: @color-white;
			box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
			backdrop-filter: blur(5px);
			border-radius: 10px;
			padding: 8px;
			
			display: inline-flex;
			position: relative;
			
			&.practice-test-box {
				top: 175px;
				right: 230%;
			}
			
			&.study-guides-box {
				top: 100px;
				right: 110%;
			}
			
			&.certificate-box {
				top: 200px;
				right: 165%;
			}
			
			.icon-container {
				background: rgba(244, 129, 32, 0.2);
				border-radius: 10px;
				width: 36px;
				height: 36px;
				
				img {
					width: 28px;
					margin-top: 4px;
					margin-left: 4px;
				}
			}
			
			.label {
				font-weight: @font-weight-bolder;
				white-space: nowrap;
				margin-left: 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: @font-size-tiny;
			}
			
			@media (min-width: @screen-tablet) {
				
				padding: 12px;
				
				.icon-container {
					width: 50px;
					height: 50px;
					
					img {
						width: 38px;
						margin-top: 6px;
						margin-left: 6px;
					}
				}
				
				&.practice-test-box {
					top: 320px;
					right: 280%;
				}
				
				&.study-guides-box {
					top: 420px;
					right: 130%;
				}
				
				&.certificate-box {
					top: 430px;
					right: 300%;
				}
				
				.label {
					font-size: @font-size-small;
					margin-left: 10px;
				}
			}
		}
		
		@media (min-width: @screen-tablet) {
			width: @figure-width;
			max-width: @figure-max-width;
			position: absolute;
			bottom: 0;
			margin: 0;
			right: ~"calc(max(-274px, (100vw - @{max-width-hero}) / 2 - 270px))";
			
			img {
				width: 600px;
				margin-left: 0;
			}
		}
	}
}

#top-features {
	position: relative;
	overflow: visible;

	@media (min-width: @screen-tablet) {
		padding-bottom: 90px;
	}
}

ul.top-feature-buttons {
	.plain-ul();
	display: grid;
	grid-column-gap: @spacing-standard;
	grid-row-gap: @spacing-standard;
	margin-top: 30px;

	grid-template: auto / auto;

	@media (min-width: @screen-tablet) {
		grid-template: auto / repeat(auto-fit, minmax(120px, 1fr));
		margin-top: 50px;
	}

	li:not(.link-inside), li a {
		
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		padding-top: 45px;
		padding-left: 34px;
		padding-right: 34px;
		padding-bottom: 34px;
		
		figure img {
			height: 63px;
			color: @color-brand;
		}
		
		.title {
			font-weight: @font-weight-bolder;
			font-size: @font-size;
			
			@media (min-width: @screen-tablet) {
				font-size: @font-size-medium;
			}
		}
		
		p {
			margin-top: 20px;
		}
	}
}

#we-offer {
	padding-top: @spacing-standard;
	padding-bottom: @spacing-standard;
	
	h2 {
		text-align: left;
		font-size: @font-size;
		margin-bottom: 20px;
		
		@media (min-width: @screen-tablet) {
			font-size: @font-size-mediumer;
			margin-bottom: 30px;
		}
	}
	
	.list-title {
		margin-top: 20px;
		
		@media (min-width: @screen-tablet) {
			margin-top: 30px;
		}
	}
	
	.box {
		box-shadow: 0 8px 34px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		padding: 50px 20px;
		background: #fff;
		@media (min-width: @screen-tablet) {
			padding: 60px 75px;
		}
	}
	
	ul {
		padding-inline-start: 23px;
		
		li:not(:last-child) {
			margin-bottom: 15px;
			
			@media (min-width: @screen-tablet) {
				margin-bottom: 20px;
			}
		}
		
		::marker {
			color: @color-brand;
			font-size: 23px;
			line-height: @font-size;
			
			@media (min-width: @screen-tablet) {
				line-height: @font-size-medium;
			}
		}
	}
}

#popular-exams {
	background-color: @color-brand-light-bg;
	position: relative;
	padding-bottom: 80px;
	
	h3 {
		font-size: @font-size;
		margin-bottom: 20px;
	}
	
	.practice-tests-link {
		margin-top: 40px;
	}
	
	@media (min-width: @screen-tablet) {
		padding-bottom: 100px;
		
		ul,
		h2 {
			margin-top: 60px;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
		}
		
		h2 {
			margin-top: 0;
			text-align: left;
		}
		
		h3 {
			font-size: @font-size-medium;
		}
	}
	
	@media (min-width: @screen-xl) {
		background-image: url('/resources/img/71/cheerful-blonde-woman-wears-optical-round-spectacles.png');
		background-repeat: no-repeat;
		background-position-y: bottom;
		background-size: 46rem;
		
		ul,
		h2 {
			margin-right: 0;
		}
	}
}

ul.popular-cert-grid {
	.plain-ul();

	display: grid;
	column-gap: @spacing-standard;
	row-gap: 70px;
	grid-template: auto / repeat(auto-fit, minmax(300px, 1fr));
	
	@media (min-width: @screen-laptop) {
		column-gap: 40px;
	}
	
	.link-action:after {
		.material-skinny-arrow();
	}
}

.testimonial-quotes {
	.plain-ul();

	li:not(:first-child) {
		margin-top: 45px;
	}

	.quote {
		font-family: @font-family-header;
		font-style: italic;
		font-weight: normal;
		font-size: 24px;
		line-height: 150%;
	}

	.name {
		text-align: center;

		@media(min-width: @screen-tablet) {
			text-align: left;
		}

		letter-spacing: 0.1px;
		text-transform: uppercase;
		margin-top: @spacing-paragraph;
	}
}

.split-bg {
	background: linear-gradient(180deg, @color-white 50%, #FEF8F2 50%);
	
}