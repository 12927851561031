@import (reference) "../global/components/expertContributions.less";
@import (reference) "../mixins/_styleResets.less";
@import (reference) "_variables.less";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400italic,400,600,300,700');

@import "./components/_allSuitesBox.less";
@import "./components/_faqs.less";
@import "./components/_tableOfContents.less";
@import "./components/_tableOfContentsBar.less";
@import "../global/components/_dial-gauge.less";
@import "../global/components/_plainExam.less";
@import "../global/components/_practice-questions-module.less";
@import "../global/components/_testimonial.less";


.individual-test {
	h1 {
		text-align: center;
	}
	
	button.next {
		text-align: center;
	}
	
	.exam-container {
		margin-top: @spacing-large;
	}
	
	.passage {
		background-color: @color-gray-e;
	}
	
	.exam-container h2 {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}

.index {
}

.suite {
	@import "pages/_suite.less";
}

.homepage {
	@import "pages/_home.less";
}

.ppc {
	@import "pages/_ppc.less";
}

.shop {
	@import "pages/_shop.less";
}

.about-us {
	@import "pages/_about_us.less";
}

.expert-contributions-container {
	.expert-contributions(@color-brand, @color-gray-2, @color-link);
}

.fusion-small {
	width: 200px;
	margin: 30px auto;
}

.pill-button {
	.pill-button();
}

.table-of-contents {
	padding-bottom: @spacing-standard;
	margin-bottom: 0;
	margin-top: 0;
	
	@media (min-width: @screen-tablet) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.video-lessons {
	text-align: center;
	
	.video-lesson__header {
		.font-h2();
		margin-bottom: 30px;
	}
	
	p {
		margin: 0 0 20px;
	}
	
	.video-lessons__video-container {
		position: relative;
		border-radius: @border-radius-large;
		display: inline-block;
		border: 3px solid @color-brand;
	}
	
	.video-lessons__tcc-banner {
		color: #fff;
		background: @color-brand;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 14%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.video-lessons__image {
		border-radius: @border-radius-large;
		width: 100%;
	}
	
	.video-lessons__video-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		background: black;
		height: 100%;
		width: 100%;
		opacity: .4;
		border-radius: @border-radius-large;
	}
	
	.video-lessons__image-container {
	}

	.video-lessons__image {
	}
}

.exam-container.exam-container--practice-module {
	margin-top: @spacing-large;
	margin-bottom: @spacing-large;
	@media (min-width: @screen-laptop) {
		margin: 90px auto;
	}
}

.error--body {
	text-align: center;
	padding: 160px 0 100px;
	
	@media (min-width: @screen-laptop) {
		padding: 180px 0 100px;
	}
}

.squish:not(.ppc):not(.homepage){
	
	@font-family-squish: 'Open Sans', sans-serif;
	*, table.responsive-table td:before {
		font-family: @font-family-squish !important;
	}
	.font-sizes(@mobile-size: @font-size-16);
	
	h1{
		.font-sizes(@desktop-size: @font-size-48, @tablet-size: @font-size-34,@mobile-size: @font-size-24);
		font-weight: @font-weight-bold;
	}
	h2 {
		.font-sizes(@desktop-size: @font-size-36, @mobile-size: @font-size-22);
		font-weight: @font-weight-bold;
		line-height: 140%;
	}
	h2.lg-space {
		.section-spacing(@margin-top-mobile: @section-spacing-15, @margin-bottom-mobile: @section-spacing-15);
		font-weight: @font-weight-bold;
	}
	
	.content--cta h2 {
		.font-sizes(@mobile-size: @font-size-24);
		font-weight: @font-weight;
	}
	.content--cta h2.faqs__header {
		font-weight: @font-weight-bold;
	}
	.exam-container--practice-module h2 {
		.font-sizes(@mobile-size: @font-size-24, @desktop-size: @font-size-28);
	}
	
	h3 {
		.font-sizes(@desktop-size: @font-size-28, @mobile-size: @font-size-20);
		font-weight: @font-weight-bold;
	}
	
	.image-and-detail__title {
		.font-sizes(@mobile-size: @font-size-24, @desktop-size: @font-size-28);
		.section-spacing(@margin-bottom-mobile: @section-spacing-8);
		font-weight: @font-weight;
	}
	.image-and-detail__description {
		.font-sizes(@mobile-size: @font-size-16);
		line-height: 140%;
	}
	
	.table-of-contents-bar__list-item--bold {
		.font-sizes(@mobile-size: @font-size-18);
		font-weight: @font-weight-bold;
	}
	
	.faqs__faq__header {
		.font-sizes(@mobile-size: @font-size-16);
		font-weight: @font-weight;
	}
	
	
	.white-bleed {
		.section-spacing(@padding-top-mobile: @section-spacing-15,
			@padding-bottom-mobile: @section-spacing-15,
			@padding-top-tablet: @section-spacing-30,
			@padding-bottom-tablet: @section-spacing-30);
		
		&:not(:first-child){
			.section-spacing(@margin-top-mobile: @section-spacing-15, @margin-top-tablet: @section-spacing-30);
		}
		&:not(:last-child){
			.section-spacing(@margin-bottom-mobile: @section-spacing-15, @margin-bottom-tablet: @section-spacing-30);
		}
	}
	
	
	//exam stuff
	.exam-container.exam-container--practice-module {
		.section-spacing(@margin-top-mobile: @section-spacing-15;
			@margin-bottom-mobile: @section-spacing-15;
			@margin-top-tablet: @section-spacing-15;
			@margin-bottom-tablet: @section-spacing-15;
			@margin-top-desktop: @section-spacing-30;
			@margin-bottom-desktop: @section-spacing-30);
	}
	
	#practice-exam.exam-container.exam-container--practice-module .exam__subheader {
		.section-spacing(@margin-bottom-mobile: @section-spacing-8; @margin-top-mobile: @section-spacing-8);
	}
	.prompt-number, .prompt-text {
		font-size: 16px !important;
	}
	
	.practice-questions-module {
		@media(min-width: @screen-tablet){
			padding: @section-spacing-30;
		}
	}
	
	.question .answersContainer li, .question .assessment-options li {
		@media(min-width: 600px){
			min-height: 30px;
		}
		@media(min-width: @screen-tablet){
			padding: 0;
		}
	}
	
	.assessment-options--multiple-choice .exam-question__options, .assessment-options--multiple-select .exam-question__options {
		@media(min-width: @screen-tablet){
			grid-gap: 0;
		}
	}
	
	.practice-questions-module__button {
		.section-spacing(@margin-top-mobile: 0);
		.font-sizes(@mobile-size: @font-size-16; @tablet-size: @font-size-14);
		height: 46px;
		padding: 0 40px;
	}
	
	#practice-exams {
		min-height: 300px;
	}
	
	.bullet {
		::marker {
			color: #000;
			font-size: 16px;
			line-height: 16px;
			
			@media (min-width: @screen-tablet) {
				line-height: 16px;
			}
		}
	}
	
	
	//ctas stuff
	.immediate-help {
		.image-and-detail {
			@media(min-width: @screen-tablet){
				gap: @section-spacing-30
			}
		}
		.image-and-detail__content {
			.section-spacing(@margin-bottom-tablet: 0)
		}
		
		.stat-card__detail {
			@media(min-width: @screen-tablet){
				padding: 0;
				text-align: left;
			}
		}
		.stat-card__image-container {
			width: auto;
			height: 40px;
			@media (min-width: @screen-tablet) {
				margin-bottom: 0;
			}
		}
		
		.stat-card__text {
			font-weight: @font-weight-bold;
			line-height: 140%;
			color: #000;
			.font-sizes(@mobile-size: @font-size-12, @tablet-size: @font-size-14);
		}
		
		.stat-card {
			gap: @section-spacing-8;
			
			@media (min-width: @screen-tablet) {
				flex-direction: row;
				padding: @section-spacing-15;
				gap: @section-spacing-15;
			}
		}
	}
	.white-bleed.image-and-detail__bleed-tablet {
		background: url(/resources/img/71/tablet-score-transparent.png) center bottom no-repeat @color-brand-light-bg;
		background-size: 389px;
		@media(min-width: @screen-tablet){
			background:@color-brand-light-bg;
		}
		
		.image-and-detail--inverse {
			@media(min-width: @screen-tablet){
				flex-direction: row-reverse;
				align-items: stretch	;
			}
		}
		
		.image-and-detail__content li {
			padding-bottom: @section-spacing-8
		}
		
		.image-and-detail__subheader {
			.section-spacing(@margin-bottom-mobile: @section-spacing-15);
		}
		.image-and-detail__content {
			.section-spacing(@margin-bottom-tablet: 0);
		}
		.image-and-detail__image-container {
			@media(min-width: @screen-tablet){
				height: auto
			}
		}
		.image-and-detail__image {
			@media(max-width: @screen-mobile-max){
				display: none;
			}
			width: 160%;
			height: auto;
			position: absolute;
			bottom: -30px;
			left: -60px;
			
			@media(min-width: @screen-laptop){
				width: 130%;
			}
			@media(min-width: @screen-desktop){
				width: 100%;
			}
		}
	}
	.video-lessons {
		@media(min-width: @screen-tablet){
			display: flex;
			gap: 30px;
			align-items: center;
		}
		.video-lesson {
			.section-spacing(@margin-bottom-mobile: @section-spacing-15);
		}
		.video-lesson__header {
			.font-sizes(@mobile-size: @font-size-24; @desktop-size: @font-size-28);
			font-weight: @font-weight;
			margin-bottom: @section-spacing-15;
		}
		.video-lessons__content {
			@media(min-width: @screen-tablet){
				text-align: left;
			}
		}
	}
	.cta-video-lessons-small {
		h2{font-weight:@font-weight-bold;}
		.cta-button-container {
			.section-spacing(@margin-top-mobile: 0; @margin-top-tablet: @section-spacing-15);
		}
	}
	#video-lessons  {
		.image-and-detail__content li {
			padding-bottom: @section-spacing-8
		}
	}
	
	//hero stuff
	.hero .content, .hero-container {
		@media(min-width: @screen-tablet){
			display: flex;
			flex-direction: row;
			gap: @section-spacing-30
		}
	}
	header.hero {
		@media(min-width: @screen-desktop){
			background: url(/resources/img/71/smiling-showing-thumbs-up.png) right top no-repeat #FEF8F2
		}
	}
	.hero-highlight-box{
		width: 100%;
		padding: 30px;
		max-width: 410px;
		.section-spacing(@margin-top-mobile: @section-spacing-15; @margin-top-desktop: 0);
		@media(min-width: @screen-tablet){
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		
		.box-title {
			.font-sizes(@mobile-size: @font-size-20);
			.section-spacing(@margin-bottom-mobile: @section-spacing-15; @margin-bottom-tablet: 0 );
			color: @color-brand;
		}
		p {
			.font-sizes(@mobile-size: @font-size-16);
			font-weight: @font-weight-bold;
		}
		.action-dropdown {
			flex-direction: column;
			@media(min-width: @screen-tablet){
				flex-direction: row;
				gap: @section-spacing-15;
			}
		}
		.index-dropdown {
			margin-right: 0;
			width: 100%;
			@media(min-width: @screen-tablet){
				width: 200px;
			}
		}
		.pill-button {
			height: auto;
			box-shadow: none;
			@media(min-width: @screen-tablet){
				width: auto;
				margin-top: 0;
			}
		}
		label select {
			min-width: unset;
		}
		select.index-dropdown {
			padding-top: 11px;
			padding-bottom: 11px;
		}
	}
	
	
	.nav-jumplinks {
		.section-spacing(@margin-bottom-mobile: @section-spacing-15; @margin-bottom-tablet: @section-spacing-30);
	}
	
	.faqs__list {
		.section-spacing(@margin-top-mobile: @section-spacing-15);
	}
	
	.pill-button {
		.font-sizes(@mobile-size: @font-size-16, @tablet-size: @font-size-14);
		margin-top: @section-spacing-15;
		padding-top: @section-spacing-15;
		padding-bottom: @section-spacing-15;
		height: auto;
	}
	
	.squish-toc{
		.index-dropdown{
			padding: 5px 10px;
			border-radius: 8px;
		}
		
		@media(min-width: @screen-laptop){
		.table-of-contents-bar__list--desktop {
			display: block;
			column-count: 3;
			list-style: disc;
			margin-left: 20px;
		}
		
		
			li.table-of-contents-bar__list-item {
				padding-bottom: 10px;
			}
		}
	}
	
	.exam-info-select-practice-test-section {
		.hero-highlight-box{
		
		}
		.action-dropdown {
			flex-direction: column;
			@media(min-width: @screen-laptop){
				width: 300px;
			}
		}
		.index-dropdown {
			@media(min-width: @screen-tablet){
				width: 100%;
			}
		}
		.pill-button {
			width: 100%;
		}
	}
	
	
	.content ol, .content ul {
		margin-bottom: 30px;
		
		+ p {
			margin-top:0;
		}
	}
	
	/*footer*/
	footer .copyright {
		.font-sizes(@mobile-size: @font-size-14; @tablet-size: @font-size-12; @desktop-size: @font-size-14);
		
	}
	.footer-container {
		.links .footer-header {
			.font-sizes(@mobile-size: @font-size-22; @tablet-size: @font-size-14; @desktop-size: @font-size-16);
			font-weight: @font-weight-bold;
		}
		.links .footer-section ul.list-items a,.links .footer-section ul.wrap-items>li a {
			.font-sizes(@mobile-size: @font-size-14; @tablet-size: @font-size-12; @desktop-size: @font-size-14);
			
		}
		
		
		.links .footer-section ul.wrap-items.state-grid {
			display: grid;
			grid-template-columns: repeat(7, minmax(33px, 1fr));
			gap: 20px;
			column-gap: 12px;
			grid-auto-flow: row !important;
		}
	}
	
}