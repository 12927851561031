@import (reference) "../_variables.less";

.shopping-course__pitch {
	font-size: 20px;
	
	@media(max-width: @screen-mobile-max) {
		padding-bottom: 30px;
	}
	
	.shopping-course__pitch__emphasis {
		font-weight: @font-weight-bold;
	}
	
	.shopping-course__pitch__bullets {
		ul {
			padding-top: 10px;
		}
		
		li {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	
	&.benefits {
		@media(max-width: @screen-mobile-max) {
			padding-bottom: 30px;
		}
	}
}
