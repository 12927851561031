@import (reference) "../../mixins/_styleResets.less";
@import (reference) "src/main/webapp/less/mixins/_icons.less";

#test-prep-claims {
	padding-bottom: @spacing-standard;
	
	@media (max-width: 600px) {
		padding-left: 28px;
		padding-right: 28px;
	}
	
	.test-prep-claims__title {
		margin-bottom: 45px;
	}
	
	.test-prep-claims__claims {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: @spacing-standard;
		margin-top: @spacing-paragraph;
		
		@media (max-width: @screen-tablet) {
			grid-column-gap: 0;
		}
		
		@media (max-width: 600px) {
			display: block;
			margin-top: 20px;
		}
		
		@media (min-width: @screen-tablet) {
			padding-left: 75px;
			padding-right: 75px;
		}
		
		.test-prep-claims__claim.hidden-xs {
			@media (max-width: 600px) {
				display: none;
			}
		}
		
		.test-prep-claims__claim {
			@fusion-width: 230px;
			
			display: flex;
			flex-direction: column;
			align-items: center;
			
			padding: 20px 0;
			margin-bottom: 50px;
			
			@media (max-width: @screen-tablet) {
				background: revert;
				border: revert;
			}
			
			@media (max-width: 600px) {
				padding: 0;
				
				.fusion-small {
					width: @fusion-width;
					height: 215px;
					margin: 0;
				}
			}
			
			
			.test-prep-claim__stat {
				display: flex;
				align-items: center;
				justify-content: center;
				
				color: @color-white;
				background-color: @color-brand;
				
				font-family: @font-family-header;
				font-size: @font-size-larger-not-huge;
				font-weight: 700;
				border-radius: 50%;
				width: 97px;
				height: 97px;
				
				@media (max-width: 600px) {
					width: 105px;
					height: 105px;
					font-size: 34px;
				}
				
				&:after {
					content: '%';
					font-family: @font-family-header;
				}
			}
			
			.test-prep-claim__text {
				.font-body();
				font-size: @font-size;
				text-align: center;
				margin-top: @spacing-paragraph;
				
				em {
					font-style: normal;
					font-weight: @font-weight-bold;
				}
				
				@media (max-width: 600px){
					margin-top: 25px;
					//font-size: @font-size-large;
					
					em {
						//font-size: @font-size-larger;
					}
				}
			}
		}
	}
}